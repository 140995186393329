import '~/tailwind.css'

import { unstable_defineLoader as defineLoader } from '@remix-run/node'
import type { MetaFunction } from '@remix-run/react'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { Toaster } from 'sonner'

import { PlausibleScript } from '~/PlausibleScript'
import { commitSession, getSession } from '~/session.server'
import { createTitle } from '~/title'
import { useToast } from '~/use-toast'

export const meta: MetaFunction = () => {
  return [{ title: createTitle() }]
}

export const loader = defineLoader(async ({ request, response }) => {
  const session = await getSession(request.headers.get('Cookie'))

  const globalMessage = session.get('globalMessage')
  response.headers.set('Set-Cookie', await commitSession(session))

  return { globalMessage }
})

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return <div>Something went wrong</div>
}

export default function App() {
  useToast()

  return (
    <html lang='en' className='h-full'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
        {process.env.NODE_ENV === 'production' ? <PlausibleScript /> : null}
      </head>
      <body className='h-full bg-white dark:bg-zinc-900'>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <Toaster theme='system' />
      </body>
    </html>
  )
}
