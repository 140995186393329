import type { UIMatch } from '@remix-run/react'
import { useMatches } from '@remix-run/react'
import { useEffect } from 'react'
import { toast } from 'sonner'

import type { GlobalMessage } from '~/session.server'

function hasGlobalMessage(
  match: UIMatch
): match is UIMatch<{ globalMessage: GlobalMessage }> {
  return (
    typeof match.data === 'object' &&
    match.data !== null &&
    'globalMessage' in match.data
  )
}

export function useToast() {
  const matches = useMatches()
  const globalMessage = matches.find(hasGlobalMessage)?.data?.globalMessage

  useEffect(() => {
    if (!globalMessage) {
      return
    }

    const { type, message } = globalMessage

    toast[type](message)
  }, [globalMessage])
}
